import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import CustomizedTooltips from '../Tooltip';
import '../../RespondentFormReadonly/index.scss';

export default function FormQuestion({
  index, questionText, description, imageUrl, isRequired, examples,
}) {
  return (
    <>
      <Grid
        container
        wrap="nowrap"
        style={{
          alignItems: 'center',
        }}
        spacing={2}
        item
        xs={12}
        md={12}
      >
        <Grid item className="questIndex_container">
          {isRequired && <Typography className="questRequired">*</Typography>}
          <Typography className="questIndex">{`${index + 1}.`}</Typography>
        </Grid>
        <Grid item xs>
          <Typography className="questionText">
            {questionText}
          </Typography>
        </Grid>
      </Grid>
      {imageUrl && <img src={imageUrl} alt="img" className="question-image" />}
    </>
  );
}
